import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './Layout';
import Links from './Links';
import Gallery from './Gallery';
import Home from './Home'; 

function App() {
  const generateClientId = () => {
    const clientId = Math.random().toString(36).substring(2, 15); // Random client ID
    localStorage.setItem('client_id', clientId);
    return clientId;
  };

  // 2. Track Event Function
  const trackEvent = (eventName, pageLocation, linkUrl = '') => {
    const clientId = localStorage.getItem('client_id') || generateClientId();

    fetch('/track-event', { // Relative path
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client_id: clientId,
        event_name: eventName,
        page_location: pageLocation,
        link_url: linkUrl
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server error ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Event tracked successfully:', data);
      })
      .catch(error => {
        console.error('Error tracking event:', error);
      });
  };

  // 3. Custom Hook to Track Page Views
  function usePageViews() {
    const location = useLocation();

    useEffect(() => {
      trackEvent('page_view', window.location.href);
    }, [location]);
  }

  usePageViews(); // Invoke the custom hook

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="links" element={<Links trackEvent={trackEvent} />} />
        <Route path="gallery" element={<Gallery />} />
      </Route>
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
