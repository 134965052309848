import React from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import './Layout.css';

const Layout = () => {
  return (
    <div className="layout">
      <aside className="sidebar">
        <h2 className={'title'}>
          <NavLink to="/" className="nav-item-title">Chaptude</NavLink>
        </h2>
        <nav>
          <NavLink to="/links" className="nav-item" activeClassName="active">Links</NavLink>
          <NavLink to="/gallery" className="nav-item" activeClassName="active">Gallery</NavLink>
        </nav>
      </aside>
      <main className="content">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
