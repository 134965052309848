import React from 'react';

const Home = () => {
  return (
    <div>
      <h1>Welcome to my website</h1>
      <p>I draw <b>big, fat, <u>POOOOOOOPY</u></b> diapers here</p>
      <p>It's still a work in progress, alas ;;;;;;&gt; w&gt; </p>
      <break>---</break>
      <p>Commissions are <span style={{ color: '#0E9205', fontWeight: 'bold' }}>OPEN</span>, btw</p>
      <a href="https://bit.ly/3B91dOQ"> Get one here!</a>
    </div>
  );
};

export default Home;
